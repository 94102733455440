import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const firstSteps = () => {
  return (
    <Layout>
      <SEO
        title="New to the Game"
        description="If you are new to PES 2021 myClub, read this page to avoid common mistakes and make as much profit as possible from the start."
      />
      <div className="container">
        <h1>New to the Game</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>New to the Game</li>
        </ul>
        <p>
          In this section you will learn how to avoid common newbie mistakes and
          get the most out of your club from the start.
        </p>
        <p>
          As you open myClub for the first time, you will get a basic squad to
          get started. Your favorite players probably won't be in there, but
          this is how it begins. Each player has 10 contracts for you to use.
          You will also have a Superstar on loan.
        </p>
        <p>
          You will notice you have GP and Coins in the upper right corner. This
          is your{" "}
          <Link to="/coins-gp/">
            currency - you can read all about this here
          </Link>
          , but you're probably eager to start a game, so let's go! Just don't
          spend on anything just yet.
        </p>
        <p>
          In the first couple of games, you will notice that pretty much
          everything you do will award you GP - even basic things such as a
          skill or a manual pass. This is great to build some momentum, and you
          will also unlock some coins by completing some achievements.
        </p>
        <p>
          Now that you have completed your first couple of games, here is what I
          would do:
        </p>
        <ul>
          <li>
            Spend coins in a top Manager ASAP. This should be the priority, as
            you won't be able to get a top manager with GP at the beginning. You
            can't change his preferred formation, so choose wisely.{" "}
            <Link to="/managers/">
              Have a look at some of the Available Managers here
            </Link>
            .
          </li>
          <li>
            Never spend coins to open boxes - use them to get Featured Players.
            Coins are much harder to come by than GP. If there are no exciting
            Featured Players on that week, I advise you to save the coins.
          </li>
          <li>
            When you decide to play online, complete the "Introductory Online
            Challenge" competition first (should be inside WHAT'S NEW / ONLINE
            CHALLENGE CUP) - it will give you 125.000 GP (25.000 GP per game),
            and those should be the first 5 games you play online.
          </li>
          <li>
            Check the list of remaining achievements to increase the amount of
            coins you have, by visiting "myClub Records" in the CLUB HOUSE
            screen.
          </li>
          <li>Complete all challenges (online and VS COM) for the week.</li>
          <li>
            Don't sell your players after their contracts expire. If you have no
            plans on renewing their contracts,{" "}
            <Link to="/training-players/">
              convert them into trainers and use them to level up your favorite
              players
            </Link>
            . If you don't have any favorite players yet, just save the
            trainers.
          </li>
        </ul>
        <p>
          You can now explore the game at your leisure.{" "}
          <Link to="/myclub-tips-tricks/">
            Check out this section for ways to get more GP
          </Link>{" "}
          and make sure you read all my{" "}
          <Link to="/myclub-tips-tricks/">PES myClub tips</Link>.
        </p>
      </div>
    </Layout>
  )
}

export default firstSteps
